// src/components/Card.js

import React from 'react';
import { Card as MuiCard, CardContent, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Helper function to determine text color based on background color and card type
const getTextColor = (bgColor, theme, type) => {
  // For sysInfo cards, adapt the text color based on the mode (light or dark)
  if (type === 'sysInfo') {
    return theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.secondary;
  }

  if (!bgColor) {
    return theme.palette.text.primary;
  }

  // Simple luminance check for better text color determination
  const hex = bgColor.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.6 ? theme.palette.text.primary : theme.palette.common.white;
};

function Card({ title, value, color, icon: Icon, children, textColor: customTextColor, type }) {
  const theme = useTheme(); // Access the theme

  // Determine background color based on card type and theme mode
  let backgroundColor;
  if (type === 'sysInfo') {
    backgroundColor = theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f8f9fa';
  } else {
    backgroundColor = color || theme.palette.background.paper;
  }

  // Determine text color
  const textColorFinal = customTextColor || getTextColor(backgroundColor, theme, type);

  return (
    <MuiCard
      sx={{
        backgroundColor,
        color: textColorFinal,
        borderRadius: theme.shape.borderRadius,
        minHeight: theme.spacing(type === 'sysInfo' ? 10 : 15), // Smaller height for sysInfo cards
        minWidth: theme.spacing(30), // Uses theme spacing (240px = 8 * 30)
        boxShadow: theme.shadows[3], // Uses theme shadows
        display: 'flex',
        alignItems: type === 'sysInfo' ? 'flex-start' : 'center', // Align sysInfo cards to the top
        padding: theme.spacing(2.5), // 20px padding based on theme spacing
        gap: theme.spacing(2), // Space between icon and text content
      }}
    >
      {Icon && (
        <Icon sx={{ fontSize: 40, color: textColorFinal }} aria-label={`${title} icon`} />
      )}
      <CardContent
        sx={{
          padding: 0,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: type === 'sysInfo' ? 'flex-start' : 'center', // Align sysInfo content to the top
          gap: theme.spacing(1.5), // Uses theme spacing for gap between elements (12px)
          color: textColorFinal, // Apply text color across the content
        }}
      >
        {title && (
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: theme.typography.subtitle1.fontSize, // Uses theme typography
              fontWeight: theme.typography.fontWeightBold,
              color: textColorFinal, // Apply text color based on theme
              textAlign: 'left',
              lineHeight: 1.2,
            }}
          >
            {title}
          </Typography>
        )}
        {value !== undefined && (
          <Typography
            variant="h5"
            sx={{
              fontSize: theme.typography.h5.fontSize, // Uses theme typography
              fontWeight: theme.typography.fontWeightBold,
              color: textColorFinal, // Apply text color based on theme
              textAlign: 'left',
            }}
          >
            {value}
          </Typography>
        )}
        {children && (
          <Box sx={{ textAlign: 'left', color: textColorFinal }}> {/* Ensure child elements also respect the color */}
            {children}
          </Box>
        )}
      </CardContent>
    </MuiCard>
  );
}

export default Card;
