// src/cardConfig.js

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import UpdateIcon from '@mui/icons-material/Update';
import StoreIcon from '@mui/icons-material/Store'; // For Etsy
import LocalFloristIcon from '@mui/icons-material/LocalFlorist'; // For Essentivit
import StorefrontIcon from '@mui/icons-material/Storefront'; // For eBay
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'; // Alternative for Amazon

const getTrendIcon = (trend) => {
  if (trend === 'up') return TrendingUpIcon;
  if (trend === 'down') return TrendingDownIcon;
  return AutoGraphIcon;
};

const cardConfig = {
  monthly: [
    {
      title: "Current Month Revenue",
      valueKey: "sales_monthly_current_total_revenue",
      color: "primary.light",
      icon: TrendingUpIcon,
      formatValue: (value) => `€${parseFloat(value).toLocaleString()}`,
    },
    {
      title: "Previous Month Revenue",
      valueKey: "sales_monthly_previous_total_revenue",
      color: "secondary.light",
      icon: TrendingDownIcon,
      formatValue: (value) => `€${parseFloat(value).toLocaleString()}`,
    },
    {
      title: "Current Month Units Sold",
      valueKey: "sales_monthly_current_total_units",
      color: "info.light",
      icon: TrendingUpIcon,
      formatValue: (value) => parseFloat(value).toLocaleString(),
    },
    {
      title: "Predicted Monthly Revenue",
      valueKey: "predicted_sales_monthly_total_revenue",
      trendKey: "predicted_sales_monthly_revenue_trend",
      color: "info.light",
      icon: (trend) => getTrendIcon(trend),
      formatValue: (value) => `€${parseFloat(value).toLocaleString()}`,
    },
    {
      title: "Previous Month Units Sold",
      valueKey: "sales_monthly_previous_total_units",
      color: "error.light",
      icon: TrendingDownIcon,
      formatValue: (value) => parseFloat(value).toLocaleString(),
    },
    {
      type: 'percentageChange',
      title: 'Percentage Changes',
      items: [
        {
          title: 'Revenue Change',
          valueKey: 'sales_monthly_percentage_revenue_change',
          trendKey: 'sales_monthly_revenue_trend',
          formatValue: (value) => (value !== 'N/A' ? `${value}%` : 'N/A'),
        },
        {
          title: 'Units Sold Change',
          valueKey: 'sales_monthly_percentage_units_change',
          trendKey: 'sales_monthly_units_trend',
          formatValue: (value) => (value !== 'N/A' ? `${value}%` : 'N/A'),
        },
      ],
    },
  ],
  daily: [
        // **Date Card**
        {
          type: "date",
          title: "Totals For",
          valueKey: "sales_last_updated",
          color: "#00ACC1",
          icon: UpdateIcon,
          formatValue: (value) => {
            const date = new Date(value);
            return date.toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            });
          },
        },
        // **Total Units and Revenue**
        {
          type: "total",
          title: "Total Unit Sales",
          valueKey: "sales_daily_total_units",
          color: "#1E88E5",
          icon: ShoppingCartIcon,
          formatValue: (value) => Math.round(value).toLocaleString(),
        },
        {
          type: "total",
          title: "Total Revenue",
          valueKey: "sales_daily_total_revenue",
          color: "#43A047",
          icon: AccountBalanceIcon,
          formatValue: (value) => `€${parseFloat(value).toFixed(2).toLocaleString()}`,
        },
        // **Marketplace-Specific Cards**
        {
          type: "marketplace",
          title: "Etsy Units Sold",
          valueKey: "sales_daily_etsy_units",
          color: "#FF5722",
          icon: StoreIcon,
          formatValue: (value) => Math.round(value).toLocaleString(),
        },
        {
          type: "marketplace",
          title: "Etsy Revenue",
          valueKey: "sales_daily_etsy_revenue",
          color: "#FF9800",
          icon: AccountBalanceIcon,
          formatValue: (value) => `€${parseFloat(value).toFixed(2).toLocaleString()}`,
        },
        {
          type: "marketplace",
          title: "essentiVit Units Sold",
          valueKey: "sales_daily_essentivit_units",
          color: "#9C27B0",
          icon: LocalFloristIcon,
          formatValue: (value) => Math.round(value).toLocaleString(),
        },
        {
          type: "marketplace",
          title: "essentiVit Revenue",
          valueKey: "sales_daily_essentivit_revenue",
          color: "#BA68C8",
          icon: AccountBalanceIcon,
          formatValue: (value) => `€${parseFloat(value).toFixed(2).toLocaleString()}`,
        },
        {
          type: "marketplace",
          title: "eBay Units Sold",
          valueKey: "sales_daily_ebay_units",
          color: "#3F51B5",
          icon: StorefrontIcon,
          formatValue: (value) => Math.round(value).toLocaleString(),
        },
        {
          type: "marketplace",
          title: "eBay Revenue",
          valueKey: "sales_daily_ebay_revenue",
          color: "#7986CB",
          icon: AccountBalanceIcon,
          formatValue: (value) => `€${parseFloat(value).toFixed(2).toLocaleString()}`,
        },
        {
          type: "marketplace",
          title: "Amazon Units Sold",
          valueKey: "sales_daily_amazon_units",
          color: "#4CAF50",
          icon: ShoppingCartIcon,
          formatValue: (value) => Math.round(value).toLocaleString(),
        },
        {
          type: "marketplace",
          title: "Amazon Revenue",
          valueKey: "sales_daily_amazon_revenue",
          color: "#81C784",
          icon: AccountBalanceIcon,
          formatValue: (value) => `€${parseFloat(value).toFixed(2).toLocaleString()}`,
        },
  ],
  lifetime: [
    {
      title: "Total Lifetime Units Sold",
      valueKey: "sales_lifetime_total_units",
      color: "primary.light",
      icon: TrendingUpIcon,
      formatValue: (value) => Math.round(value).toLocaleString(),
    },
    {
      title: "Total Lifetime Revenue",
      valueKey: "sales_lifetime_total_revenue",
      color: "secondary.light",
      icon: TrendingUpIcon,
      formatValue: (value) =>
        `€${parseFloat(value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
  ],
  records: [
    {
      title: "Highest Sales in Last 30 Days",
      valueKey: "record_sales_total_day_last_30_days",
      color: "primary.light",
      icon: TrendingUpIcon,
      formatValue: (value) => `€${parseFloat(value).toLocaleString()}`,
      additionalInfo: {
        dateKey: "record_sales_total_day_last_30_days_date",
        formatDate: (date) => {
          const formattedDate = new Date(date);
          return formattedDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
        },
        text: "Achieved on",
      },
    },
    {
      title: "Highest Single-Day Sales",
      valueKey: "record_sales_total_day",
      color: "secondary.light",
      icon: TrendingUpIcon,
      formatValue: (value) => `€${parseFloat(value).toLocaleString()}`,
      additionalInfo: {
        dateKey: "record_sales_total_day_date",
        formatDate: (date) => {
          const formattedDate = new Date(date);
          return formattedDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
        },
        text: "Achieved on",
      },
    },
    {
      title: "Highest Units Sold in a Day",
      valueKey: "record_daily_unit_sales",
      color: "info.light",
      icon: TrendingUpIcon,
      formatValue: (value) => Math.round(value).toLocaleString(),
      additionalInfo: {
        dateKey: "record_daily_unit_sales_date",
        formatDate: (date) => {
          const formattedDate = new Date(date);
          return formattedDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
        },
        text: "Achieved on",
      },
    },
    {
      title: "Longest Consecutive Sales Days",
      valueKey: "record_consecutive_sales_day_count",
      color: "success.light",
      icon: TrendingUpIcon,
      formatValue: (value) => Math.round(value).toLocaleString(),
      additionalInfo: {
        dateKey: "record_consecutive_sales_day_date",
        formatDate: (date) => {
          const formattedDate = new Date(date);
          return formattedDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
        },
        text: "Achieved on",
      },
    },
  ],
  sysInfo: {
    type: "sysInfo",
    title: "System Information",
    // Remove the color property
    fields: [
      {
        label: "Data Last Updated",
        valueKey: "last_updated",
        formatValue: (value) => {
          if (!value) return '--/--/---- --:--:--';
          const date = new Date(value);
          return date.toLocaleString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          });
        },
      },
      {
        label: "Sheet Version",
        valueKey: "sheet_version",
        defaultValue: "--",
      },
      {
        label: "API Version",
        valueKey: "api_version",
        defaultValue: "--",
      },
      {
        label: "Dashboard Version",
        valueKey: "dashboard_version", // Will be fetched from packageJson
      },
    ],
  },
  weekly: [
    {
      title: "Current Week Revenue",
      valueKey: "sales_weekly_current_total_revenue",
      color: "primary.light",
      icon: TrendingUpIcon,
      formatValue: (value) => `€${parseFloat(value).toLocaleString()}`,
    },
    {
      title: "Previous Week Revenue",
      valueKey: "sales_weekly_previous_total_revenue",
      color: "secondary.light",
      icon: TrendingDownIcon,
      formatValue: (value) => `€${parseFloat(value).toLocaleString()}`,
    },
    {
      title: "Current Week Units Sold",
      valueKey: "sales_weekly_current_total_units",
      color: "info.light",
      icon: TrendingUpIcon,
      formatValue: (value) => parseFloat(value).toLocaleString(),
    },
    {
      title: "Previous Week Units Sold",
      valueKey: "sales_weekly_previous_total_units",
      color: "error.light",
      icon: TrendingDownIcon,
      formatValue: (value) => parseFloat(value).toLocaleString(),
    },
    {
      type: 'percentageChange',
      title: 'Percentage Changes',
      items: [
        {
          title: 'Revenue Change',
          valueKey: 'sales_weekly_percentage_revenue_change',
          trendKey: 'sales_weekly_revenue_trend',
          formatValue: (value) => (value !== 'N/A' ? `${value}%` : 'N/A'),
        },
        {
          title: 'Units Sold Change',
          valueKey: 'sales_weekly_percentage_units_change',
          trendKey: 'sales_weekly_units_trend',
          formatValue: (value) => (value !== 'N/A' ? `${value}%` : 'N/A'),
        },
      ],
    },
  ],
};

export default cardConfig;
