// src/components/RecordsTab.js

import React from 'react';
import { Grid, Container, Typography, Box } from '@mui/material';
import Card from './Card';
import cardConfig from '../cardConfig';
import { useTheme } from '@mui/material/styles';

function RecordsTab({ apiData }) {
  const theme = useTheme();

  if (!apiData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" disableGutters>
      <Grid container spacing={3} padding={3}>
        {cardConfig.records.map((card, index) => {
          const rawValue = apiData[card.valueKey];
          const formattedValue = card.formatValue
            ? card.formatValue(rawValue)
            : rawValue;

          const additionalInfo = card.additionalInfo
            ? `${card.additionalInfo.text}: ${card.additionalInfo.formatDate(
                apiData[card.additionalInfo.dateKey]
              )}`
            : null;

          return (
            <Grid item xs={12} md={6} key={index}>
              <Card
                title={card.title}
                value={formattedValue}
                color={theme.palette[card.color.split('.')[0]][card.color.split('.')[1]]}
                icon={card.icon}
              >
                {additionalInfo && (
                  <Typography variant="body2">{additionalInfo}</Typography>
                )}
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}

export default RecordsTab;
