// src/components/DailyTab.js

import React from 'react';
import { Grid, Container, Typography } from '@mui/material';
import Card from './Card';
import cardConfig from '../cardConfig';
import { useTheme } from '@mui/material/styles';

function DailyTab({ apiData }) {
  const theme = useTheme();

  if (!apiData) {
    return (
      <Container maxWidth="lg" sx={{ padding: '20px' }}>
        <Typography variant="h6">Loading...</Typography>
      </Container>
    );
  }

  // Separate cards by type
  const dateCard = cardConfig.daily.find(card => card.type === 'date');
  const totalCards = cardConfig.daily.filter(card => card.type === 'total');
  const marketplaceCards = cardConfig.daily.filter(card => card.type === 'marketplace');

  // Function to render a regular card
  const renderRegularCard = (card, key, gridProps = {}) => {
    const rawValue = apiData[card.valueKey];
    const value = rawValue !== undefined ? rawValue : 'N/A';
    const formattedValue = card.formatValue ? card.formatValue(value) : value;

    return (
      <Grid item key={key} {...gridProps}>
        <Card
          title={card.title}
          value={formattedValue}
          color={card.color}
          icon={card.icon}
          // Additional props for presentation variations can be passed here
        />
      </Grid>
    );
  };

  // Function to render marketplace-specific cards
  const renderMarketplaceCards = () => {
    return marketplaceCards.map((card, index) => {
      const key = `marketplace-card-${index}`;
      const rawValue = apiData[card.valueKey];
      const value = rawValue !== undefined ? rawValue : 'N/A';
      const formattedValue = card.formatValue ? card.formatValue(value) : value;

      return (
        <Grid item key={key} xs={12} sm={6} md={3}>
          <Card
            title={card.title}
            value={formattedValue}
            color={card.color}
            icon={card.icon}
            // Additional props for presentation variations can be passed here
          />
        </Grid>
      );
    });
  };

  return (
    <Container maxWidth="lg" sx={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
      <Grid container spacing={4} justifyContent="center">
        {/* Date Card: Full Width */}
        {dateCard && renderRegularCard(dateCard, 'date-card', { xs: 12 })}

        {/* Total Units and Total Revenue: Half Width Each */}
        {totalCards.map((card, index) =>
          renderRegularCard(card, `total-card-${index}`, { xs: 12, sm: 6 })
        )}

        {/* Marketplace-Specific Cards */}
        {renderMarketplaceCards()}
      </Grid>
    </Container>
  );
}

export default DailyTab;
