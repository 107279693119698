// src/components/WeeklyTab.js

import React from 'react';
import { Grid, Box, Container, Typography } from '@mui/material';
import Card from './Card';
import cardConfig from '../cardConfig';  // Import cardConfig
import { useTheme } from '@mui/material/styles';

function WeeklyTab({ apiData }) {
  const theme = useTheme();

  if (!apiData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" disableGutters>
      <Grid container spacing={3} padding={3}>
        {cardConfig.weekly.map((card, index) => {
          if (card.type === 'percentageChange') {
            return (
              <Grid item xs={12} key={index}>
                <Card
                  title={card.title}
                  color={theme.palette.background.paper}
                  textColor={theme.palette.text.primary}
                >
                  <Grid container spacing={2}>
                    {card.items.map((item, idx) => {
                      const rawValue = apiData[item.valueKey];
                      const value =
                        rawValue !== 'N/A' ? parseFloat(rawValue) : 'N/A';
                      const formattedValue = item.formatValue
                        ? item.formatValue(rawValue)
                        : rawValue;

                      const trend = apiData[item.trendKey];
                      const TrendIconComponent = card.icon ? card.icon(trend) : null; // Check if `icon` is a function

                      const changeColor =
                        value !== 'N/A'
                          ? value >= 0
                            ? theme.palette.success.main
                            : theme.palette.error.main
                          : theme.palette.text.primary;

                      return (
                        <Grid item xs={12} sm={6} key={idx}>
                          <Box display="flex" alignItems="center">
                            {rawValue !== 'N/A' && TrendIconComponent && (
                              <TrendIconComponent
                                sx={{
                                  color: changeColor,
                                  marginRight: 1,
                                  fontSize: 30,
                                }}
                              />
                            )}
                            <Box>
                              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                {item.title}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ color: changeColor, fontWeight: 'medium' }}
                              >
                                {formattedValue}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Card>
              </Grid>
            );
          } else {
            // Handle regular cards
            const rawValue = apiData[card.valueKey];
            const value = rawValue !== undefined ? rawValue : 'N/A';
            const formattedValue = card.formatValue
              ? card.formatValue(value)
              : value;

            return (
              <Grid item xs={12} md={6} key={index}>
                <Card
                  title={card.title}
                  value={formattedValue}
                  color={theme.palette[card.color.split('.')[0]][card.color.split('.')[1]]}
                  icon={card.icon}  // Directly use the icon component for regular cards
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </Container>
  );
}

export default WeeklyTab;
