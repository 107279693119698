// src/components/LoginHandler.js

import React, { useEffect } from 'react';
import { Button, Box, Typography, Alert } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';

const LoginHandler = () => {
  const { loginWithRedirect, logout } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  // Parse error and error_description from URL parameters
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get('error');
  const errorDescription = queryParams.get('error_description');

  useEffect(() => {
    if (error) {
      // Log the error for debugging
      console.error(`Auth0 Error: ${error} - ${errorDescription}`);
    }
  }, [error, errorDescription]);

  // Handler for retrying login: Logs out the user and redirects to /login
  const handleRetryLogin = () => {
    logout({
      returnTo: `${window.location.origin}/login`, // Redirects to /login after logout
      federated: true, // Ensures a complete logout across identity providers
    });
  };

  // Existing logout handler: Logs out the user and redirects to homepage
  const handleLogout = () => {
    logout({
      returnTo: window.location.origin, // Redirects to homepage after logout
      federated: true, // Ensures a complete logout across identity providers
    });
  };

  // Handler for initial login: Clears any error params and initiates login
  const handleLogin = () => {
    // Clear any existing error parameters before retrying login
    navigate('/login', { replace: true });
    loginWithRedirect();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        px: 2,
        textAlign: 'center',
        backgroundColor: 'background.default', // Ensure background matches theme
      }}
    >
      {error ? (
        <>
          <Alert severity="error" sx={{ mb: 2, width: '100%', maxWidth: 400 }}>
            <Typography
              variant="h6"
              gutterBottom
              color="text.primary" // Explicitly set text color for better contrast
            >
              {decodeURIComponent(errorDescription)}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary" // Use secondary text color for subtext
            >
              {error === 'access_denied'
                ? 'Please try logging in again.'
                : 'An unexpected error occurred. Please try again.'}
            </Typography>
          </Alert>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRetryLogin} // Now handles retry by logging out and redirecting
            sx={{ mb: 1, width: '100%', maxWidth: 200 }}
          >
            Retry Login
          </Button>
          {/* "Logout" button removed from the error state to avoid redundancy */}
        </>
      ) : (
        <>
          <Typography variant="h5" gutterBottom color="text.primary">
            Welcome to the Application
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin} // Initiates login
            sx={{ mb: 2, width: '100%', maxWidth: 200 }}
          >
            Login
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleLogout}
            sx={{ width: '100%', maxWidth: 200 }}
          >
            Logout
          </Button>
        </>
      )}
    </Box>
  );
};

export default LoginHandler;
