// src/App.js

import React, { useState, useEffect } from 'react';
import {
  ThemeProvider,
  CssBaseline,
  CircularProgress,
  Box,
  Snackbar,
  Slide,
  Button,
  Typography,
  Alert, // Import Alert for consistent error messages
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'; // Import useNavigate
import DashboardTabs from './components/DashboardTabs';
import NavBar from './components/NavBar';
import Header from './components/Header';
import LoginHandler from './components/LoginHandler';
import axios from 'axios';
import { lightTheme, darkTheme } from './theme';

function App() {
  const { isLoading, isAuthenticated, logout, error: authError } = useAuth0();

  const [config, setConfig] = useState(null);
  const [activeTab, setActiveTab] = useState('daily');
  const [previousTab, setPreviousTab] = useState('daily');
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [apiData, setApiData] = useState(null);
  const [isManuallyLoading, setIsManuallyLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(false);

  const navigate = useNavigate(); // Initialize navigate

  // Load configuration from config.json
  const loadConfig = async () => {
    try {
      const response = await fetch('/config.json');
      if (!response.ok) {
        throw new Error(`Failed to load config: ${response.statusText}`);
      }
      const configData = await response.json();
      setConfig(configData);
      if (configData.title) {
        document.title = configData.title;
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to load configuration.');
    }
  };

  // Effect to load configuration on component mount
  useEffect(() => {
    loadConfig();
  }, []);

  // Function to fetch data from the API
  const fetchData = async (manualRefresh = false) => {
    console.log("Fetching data, manual refresh:", manualRefresh);
    if (!config || !config.apiUrl) {
      console.error('API URL is not configured.');
      setErrorMessage('API URL is not configured.');
      return; // Exit early if config is missing
    }
    try {
      if (manualRefresh) {
        setIsManuallyLoading(true);
      }
      setIsFetching(true);

      const timestamp = new Date().getTime();
      const apiUrl = `${config.apiUrl}?t=${timestamp}`;
      const response = await axios.get(apiUrl);
      const newApiData = response.data;

      if (!lastUpdate || newApiData.last_updated !== lastUpdate) {
        setApiData(newApiData);
        setLastUpdate(newApiData.last_updated);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to load data. Please check the API URL and your internet connection.');
    } finally {
      if (manualRefresh) {
        setIsManuallyLoading(false);
      }
      setIsFetching(false);
    }
  };

  // Effect to handle automatic data fetching and refreshing
  useEffect(() => {
    if (isAuthenticated && config) {
      fetchData(true); // Initial data fetch
      const intervalId = setInterval(() => {
        fetchData(false); // Auto-refresh
      }, config.autoRefreshTimeSeconds * 1000);

      return () => clearInterval(intervalId); // Cleanup on unmount
    }
  }, [isAuthenticated, config]);

  // Handler for tab changes
  const handleTabChange = (newTab) => {
    setPreviousTab(activeTab);
    setActiveTab(newTab);
    setIsInitialLoad(false);
  };

  // Determine transition direction based on tab change
  const getTransitionDirection = () => {
    const tabOrder = ['daily', 'weekly', 'monthly', 'records', 'lifetime', 'sysinfo'];
    return tabOrder.indexOf(activeTab) > tabOrder.indexOf(previousTab) ? 'left' : 'right';
  };

  // Toggle between light and dark themes
  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  // Show spinner until authentication and config are ready
  if (isLoading || !config) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />

      <Routes>
        {/* Route for handling login and authentication errors */}
        <Route path="/login" element={<LoginHandler />} />

        {/* Main application route */}
        <Route
          path="/*"
          element={
            isAuthenticated ? (
              <>
                <Header
                  onLogoClick={() => {
                    if (config) {
                      console.log("Logo clicked, triggering data fetch...");
                      fetchData(true); // Manually refresh data when logo is clicked
                    } else {
                      console.log("Config not loaded yet.");
                    }
                  }}
                  toggleTheme={toggleTheme}
                  isDarkMode={isDarkMode}
                />
                <NavBar activeTab={activeTab} setActiveTab={handleTabChange} />
                <Slide
                  key={activeTab}
                  direction={getTransitionDirection()}
                  in={true}
                  mountOnEnter
                  unmountOnExit
                >
                  <div>
                    <DashboardTabs activeTab={activeTab} apiData={apiData} />
                  </div>
                </Slide>
              </>
            ) : (
              // Redirect to /login if not authenticated
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>

      {/* Spinner during manual fetching */}
      {(isFetching || isManuallyLoading) && (
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}

      {/* Error Snackbar */}
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      {/* Handle Auth0 errors by displaying an error message with a Logout button */}
      {authError && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            px: 2,
            textAlign: 'center',
          }}
        >
          <Alert severity="error" sx={{ mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              {authError.message}
            </Typography>
            <Typography variant="body2">
              {authError.error === 'access_denied'
                ? 'Access was denied. Please try logging in again.'
                : 'An unexpected authentication error occurred.'}
            </Typography>
          </Alert>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // Redirect to logout to clear session
              logout({ returnTo: window.location.origin, federated: true });
            }}
            sx={{ mb: 1 }}
          >
            Logout
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate('/login')}
          >
            Go to Login
          </Button>
        </Box>
      )}
    </ThemeProvider>
  );
}

export default App;
