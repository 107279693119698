// src/components/SysInfoTab.js

import React from 'react';
import { Grid, Container, Typography } from '@mui/material';
import Card from './Card';
import cardConfig from '../cardConfig';
import packageJson from '../../package.json'; // Import package.json to access the version

function SysInfoTab({ apiData }) {
  const systemInfoConfig = cardConfig.sysInfo;

  return (
    <Container maxWidth="lg" sx={{ paddingLeft: '0', paddingRight: '0' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card
            title={systemInfoConfig.title}
            type={systemInfoConfig.type}
            sx={{ textAlign: 'left', margin: '0' }}
          >
            {systemInfoConfig.fields.map((field, index) => {
              const rawValue = field.valueKey === 'dashboard_version' ? packageJson.version : apiData[field.valueKey];
              const value = field.formatValue ? field.formatValue(rawValue) : rawValue || field.defaultValue;

              return (
                <Typography key={index} variant="body1" sx={{ color: 'inherit' }}>
                  <strong>{field.label}:</strong> {value}
                </Typography>
              );
            })}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SysInfoTab;
