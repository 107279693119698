// src/theme.js

import { createTheme } from '@mui/material/styles';

// Define light and dark theme palettes
const lightPalette = {
  primary: {
    main: '#1976d2', // Customize primary color
  },
  secondary: {
    main: '#dc004e', // Customize secondary color
  },
  background: {
    paper: '#fff', // Customize default card background
    default: '#f4f6f8',
  },
  text: {
    primary: '#333', // Customize default text color
    secondary: '#666', // Customize secondary text color
  },
};

const darkPalette = {
  primary: {
    main: '#90caf9', // Lighter blue for dark mode
  },
  secondary: {
    main: '#f48fb1', // Pinkish secondary color for dark mode
  },
  background: {
    paper: '#424242', // Dark background for cards
    default: '#303030',
  },
  text: {
    primary: '#fff', // Lighter text for dark mode
    secondary: '#aaa', // Softer text color for dark mode
  },
};

// Common typography settings for both light and dark themes
const typography = {
  fontFamily: 'Roboto, Arial, sans-serif',
  h5: {
    fontSize: '2rem', // Customize h5 font size
    fontWeight: 700, // Customize h5 font weight
  },
  subtitle1: {
    fontSize: '1.25rem', // Customize subtitle1 font size
    fontWeight: 600,
  },
  body2: {
    fontSize: '1rem', // Customize body2 font size
    fontWeight: 500, // Customize body2 font weight
    color: '#fff', // This will be overridden by the palette text settings
  },
};

// Other common settings for both light and dark themes
const commonSettings = {
  spacing: 8, // Base spacing of 8px
  shape: {
    borderRadius: 4, // Customize border radius
  },
  shadows: ['none', '0px 2px 4px rgba(0,0,0,0.1)', '0px 4px 10px rgba(0,0,0,0.1)'], // Customize shadows
  
  // Spinner styling
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          size: 60, // Default size of the spinner
          thickness: 5, // Thickness of the spinner's stroke
          '@keyframes spin': { // Custom animation speed
            from: {
              transform: 'rotate(0deg)',
            },
            to: {
              transform: 'rotate(360deg)',
            },
          },
          animation: 'spin 2s linear infinite', // Custom spinner speed
        },
        circle: {
          strokeWidth: 3, // Thickness of the spinner's circle
        },
      },
    },
  },
};

// Create themes using the respective palettes
const lightTheme = createTheme({
  palette: { ...lightPalette, mode: 'light' },
  typography,
  ...commonSettings,
});

const darkTheme = createTheme({
  palette: { ...darkPalette, mode: 'dark' },
  typography,
  ...commonSettings,
});

export { lightTheme, darkTheme };
