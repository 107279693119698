// src/components/Header.js

import React, { useEffect } from 'react';
import { AppBar, Toolbar, Typography, Container, Button, useTheme, Box } from '@mui/material';
import lightLogo from '../img/essentivit-logo.png';  // Light mode logo
import darkLogo from '../img/essentivit-logo-darkmode.png';  // Dark mode logo
import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0

function Header({ onLogoClick, toggleTheme, isDarkMode }) {  // Pass toggleTheme and isDarkMode as props
  const theme = useTheme();  // Access the current theme
  const { logout } = useAuth0(); // Destructure logout from useAuth0

  // Preload the dark and light logos
  useEffect(() => {
    const preloadImage = (src) => {
      const img = new Image();
      img.src = src;
    };

    preloadImage(lightLogo);
    preloadImage(darkLogo);
  }, []);

  // Choose the appropriate logo based on the theme mode
  const logo = theme.palette.mode === 'dark' ? darkLogo : lightLogo;

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          {/* Make the logo clickable and preserve the size */}
          <img
            src={logo}
            alt="essentiVit Logo"
            style={{ height: 50, marginRight: 16, cursor: 'pointer' }} // Keep logo size, add pointer on hover
            onClick={onLogoClick}  // Handle click to refresh data
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Sales Dashboard
          </Typography>
          {/* Buttons Container */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {/* Dark mode toggle button */}
            <Button onClick={toggleTheme} variant="contained" color="primary">
              {isDarkMode ? 'Light' : 'Dark'} Mode
            </Button>
            {/* Logout Button */}
            <Button
              onClick={() => logout({ returnTo: window.location.origin })}
              variant="outlined"
              color="secondary"
            >
              Logout
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
