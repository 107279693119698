// src/components/MonthlyTab.js

import React from 'react';
import { Typography, Grid, Box, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Card from './Card';
import cardConfig from '../cardConfig';

function MonthlyTab({ apiData }) {
  const theme = useTheme();

  // Display a loading state if apiData is not yet available
  if (!apiData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  // Utility function to get color from theme using a color key
  const getColor = (colorKey) => {
    const keys = colorKey.split('.');
    let color = theme.palette;
    keys.forEach((key) => {
      color = color[key];
    });
    return color;
  };

  // Utility function to get change color based on value
  const getChangeColor = (change) => {
    if (change === 'N/A') {
      return theme.palette.text.primary;
    }
    return change >= 0 ? theme.palette.success.main : theme.palette.error.main;
  };

  return (
    <Container maxWidth="lg" disableGutters>
      <Grid container spacing={3} padding={3}>
        {cardConfig.monthly.map((card, index) => {
          if (card.type === 'percentageChange') {
            // Handle Percentage Changes card
            return (
              <Grid item xs={12} key={index}>
                <Card
                  title={card.title}
                  color={theme.palette.background.paper}
                  textColor={theme.palette.text.primary}
                >
                  <Grid container spacing={2}>
                    {card.items.map((item, idx) => {
                      const rawValue = apiData[item.valueKey];
                      const value =
                        rawValue !== 'N/A' ? parseFloat(rawValue) : 'N/A';
                      const formattedValue = item.formatValue
                        ? item.formatValue(rawValue)
                        : rawValue;
                      // Determine trend icon
                      const trend = apiData[item.trendKey];
                      let IconComponent;
                      if (trend === 'up') {
                        IconComponent = TrendingUpIcon;
                      } else if (trend === 'down') {
                        IconComponent = TrendingDownIcon;
                      } else {
                        IconComponent = AutoGraphIcon;
                      }
                      // Determine color based on value
                      const color = getChangeColor(value);
                      return (
                        <Grid item xs={12} sm={6} key={idx}>
                          <Box display="flex" alignItems="center">
                            {rawValue !== 'N/A' && (
                              <IconComponent
                                sx={{
                                  color: color,
                                  marginRight: 1,
                                  fontSize: 30,
                                }}
                                aria-label={
                                  value >= 0
                                    ? `${item.title} Increased`
                                    : `${item.title} Decreased`
                                }
                              />
                            )}
                            <Box>
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: 'bold' }}
                              >
                                {item.title}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: color,
                                  fontWeight: 'medium',
                                }}
                              >
                                {formattedValue}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Card>
              </Grid>
            );
          } else {
            // Handle regular cards
            const rawValue = apiData[card.valueKey];
            const value = rawValue !== 'N/A' ? rawValue : 'N/A';
            const formattedValue = card.formatValue
              ? card.formatValue(value)
              : value;
            const color = getColor(card.color);

            // Get the icon component
            let IconComponent;
            if (typeof card.icon === 'function') {
              const trend = apiData[card.trendKey];
              IconComponent = card.icon(trend);
            } else {
              IconComponent = card.icon;
            }

            return (
              <Grid item xs={12} md={6} key={index}>
                <Card
                  title={card.title}
                  value={formattedValue}
                  color={color}
                  icon={IconComponent}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </Container>
  );
}

export default MonthlyTab;
